<template>
  <v-row>
    <v-col cols="12" sm="6" md="4" lg="4">
      <users-carousel></users-carousel>
    </v-col>
    <v-col cols="12" sm="6" md="8" lg="8">
      <campaigns-carousel></campaigns-carousel>
    </v-col>
    <v-col cols="12" sm="6" md="5" lg="5">
      <purchases-carousel></purchases-carousel>
    </v-col>
    <v-col cols="12" sm="6" md="5" lg="5">
      <news-carousel></news-carousel>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {
    NewsCarousel: () => import("@/components/widgets/NewsCarousel"),
    CampaignsCarousel: () => import("@/components/widgets/CampaignsCarousel"),
    PurchasesCarousel: () => import("@/components/widgets/PurchasesCarousel"),
    UsersCarousel: () => import("@/components/widgets/RegisteredUsersCarousel")
  },
  data() {
    return {};
  }
};
</script>
